<template>
  <div class="container-fluid">
    <div class="row no-gutters shadow">
      <div class="col-lg-6 img-container">
        <img src="../../src/assets/Image 12.png" class="img-fluid" />
      </div>

      <div class="col-lg-6 secondCol">
        <h1 class="title">Free eBook</h1>
        <p class="form-desc">
          <strong>
            A guide on how to properly <br />
            prepare a Cash Flow statement
          </strong>
        </p>
        <form @submit.prevent="handleSendEmail" class="form shadow box">
          <p class="form-title text-center">Save time and money!</p>
          <p class="text-center">
            I present a method on how to quickly and correctly prepare a cash
            flow statement
          </p>
          <label for="name">Your name</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="name"
              aria-describedby="basic-addon3"
              placeholder="Your name"
            />
          </div>
          <label for="email">Your email</label>
          <div class="input-group mb-3">
            <input
              id="email"
              v-model="email"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="email"
              placeholder="Your email"
            />
          </div>
          <div class="form-check">
            <input
              type="checkbox"
              required
              :checked="checkbox"
              v-model="checkbox"
              class="form-check-input"
            />
            <label class="form-check-label" style="font-size:0.6rem;"
              >I consent to the processing of my personal data 
              <br />
             in accordance with the Personal Data Protection Act</label
            >
          </div>
          <div v-if="emptyEmail" class="alert alert-danger" role="alert">
            Email is required
          </div>
          <div class="text-right">
            <button
              type="submit"
              :disabled="loading"
              class="mt-4 btn btn-yellow "
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span class="btn-yellow-inner" style="font-weight:bold;"
                >I WANT THE FREE EBOOK</span
              >
            </button>
          </div>
          <div v-if="message == 200" class="alert alert-success" role="alert">
            Email has been sent.
          </div>
          <div v-else-if="message" class="alert alert-danger" role="alert">
            {{ message.message }}
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "Ebook",
  data() {
    return {
      email: "",
      loading: false,
      message: "",
      checkbox: false,
      emptyEmail: false,
    };
  },
  methods: {
    handleSendEmail() {
      if (this.checkbox) {
        this.loading = true;
        this.$validator.validateAll().then((isValid) => {
          if (!isValid) {
            this.loading = false;
            this.emptyEmail = true;
            setTimeout(() => (this.emptyEmail = false), 2000);
            return;
          }

          UserService.getSendEmailEbook(this.email).then(
            (response) => {
              this.message = response.status;
              setTimeout(() => (this.message = ""), 2000);
            },
            (error) => {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              setTimeout(() => (this.message = ""), 2000);
            }
          );
          this.loading = false;
          this.email = "";
          this.message = "";
          this.checkbox = false;
        });
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/main.css";
.title {
  margin: 60px 0;
  font-size: 30px;
  text-align: center;
}
.text-center {
  text-align: center;
}
.container-fluid {
  padding: 0;
}
label {
  font-size: 0.75rem;
  font-weight: bold;
}
@media only screen and (max-width: 520px) {
  .box {
    width: 100%;
  }
}
</style>
